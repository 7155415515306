import React from 'react';


import { Heading } from '../../components';

import css from './ListingPage.module.css';

const SectionArrayText = props => {
  const { title, options, intl } = props;
  return (
    <section className={css.sectionDetails}>
      <Heading as="h2" rootClassName={css.sectionHeading}>
        {title}
      </Heading>
      <ul className={css.details}>
        {options.map((o, i) => {
          return (
            <div className='row gap10' key={i}>
              {o.title &&

                <p className={css.text}>{o.title}</p>
              }
              {' - '}
              {o.value &&
                <p className={css.text}>{o.value}</p>
              }
            </div>
          )
        })}
      </ul>
    </section>
  )
};

export default SectionArrayText;
