import React from 'react';

// Utils
import { SCHEMA_TYPE_MULTI_ENUM, SCHEMA_TYPE_TEXT } from '../../util/types';
import {
  isFieldForCategory,
  pickCategoryFields,
  pickCustomFieldProps,
} from '../../util/fieldHelpers.js';

import SectionDetailsMaybe from './SectionDetailsMaybe';
import SectionMultiEnumMaybe from './SectionMultiEnumMaybe';
import SectionTextMaybe from './SectionTextMaybe';
import SectionSimpleText from './SectionSimpleText.js';
import SectionArrayText from './SectionArrayText.js';
import { pets, petsType } from '../../util/pets.js';
import { experienceLevels } from '../../util/experienceLevels.js';

const CustomListingFieldsHouseSitter = props => {
  const { publicData, intl } = props;

  const { jobTitle, petCareExperience, reviews } = publicData;

  const petCareExperienceFormatted = petCareExperience?.map(e => {
    return {
      title: pets.find(p => p.option === e.petType)?.label,
      value: experienceLevels.find(p => p.option === e.experienceLevel)?.label
    }
  });

  const reviewsFormatted = reviews?.map(r => {
    return {
      title: r.fullName,
      value: r.review
    }
  });

  return (
    <>
      < SectionSimpleText
        title={intl.formatMessage({ id: "ListingPage.jobTitle" })}
        text={jobTitle} />

      <SectionArrayText
        title={intl.formatMessage({ id: "ListingPage.petCareExperience" })}
        options={petCareExperienceFormatted}
      />

      <SectionArrayText
        title={intl.formatMessage({ id: "ListingPage.referencesAndReviews" })}
        options={reviewsFormatted}
      />
    </>
  );
};

export default CustomListingFieldsHouseSitter;
