import React from 'react';


import { Heading } from '../../components';

import css from './ListingPage.module.css';

const SectionSimpleText = props => {
  const { title, text, intl } = props;

  return (
    <section className={css.sectionDetails}>
      <Heading as="h2" rootClassName={css.sectionHeading}>
        {title}
      </Heading>
      <ul className={css.details}>
        {text}
      </ul>
    </section>
  )
};

export default SectionSimpleText;
