import React from 'react';
import { Heading } from '../../components';

import css from './ListingPage.module.css';
import { FormattedMessage } from 'react-intl';
import AzureImageDisplay from '../../components/AzureImageDisplay/AzureImageDisplay';
import { getPetIcon } from '../../components/ListingCard/ListingCard';

const SectionPetsMaybe = props => {
  const { pets = '', } = props;


  const filteredPets = Object.keys(pets).reduce((acc, key) => {
    if (pets[key].length > 0) {
      acc[key] = pets[key];
    }
    return acc;
  }, {});

  return (
    <section className={css.sectionText}>
      <Heading as="h2" rootClassName={css.sectionHeadingWithExtraMargin}>
        <FormattedMessage id="ListingPage.petsTitle" />
      </Heading>

      {Object.entries(filteredPets).map(([key, animalList], index) => (
        <div key={key + '-' + index}>
          <div className='row gap5'>
            <p className={css.petType}>
              <FormattedMessage id={`ListingPage.${key}`} />
            </p>
            <p className={css.count}>
              {animalList.length}
            </p>
            <img src={getPetIcon(key)} alt={`${key} icon`} className={css.petIcon} />

          </div>

          {Array.isArray(animalList) && animalList.map((animal, index) => (
            <div key={`${key}-${animal}-${index}`} className='col'>
              <div className='row'>
                {animal.images?.map(i => {
                  if (i) {
                    return <AzureImageDisplay key={i} value={i} />
                  } else {
                    return null;
                  }
                })}
              </div>
              <div className='row gap5'>
                <p className={css.petInfo}>
                  <FormattedMessage id="ListingPage.name" />
                </p>
                <p className={css.petInfo}>
                  {animal.name}
                </p>
              </div>
              <div className='row gap5'>
                <p className={css.petInfo}>
                  <FormattedMessage id="ListingPage.age" />
                </p>

                <p className={css.petInfo}>
                  {animal.age}
                </p>
              </div>

              <div className='row gap5'>
                <p className={css.petInfo}>
                  <FormattedMessage id="ListingPage.breed" />
                </p>

                <p className={css.petInfo}>
                  {animal.breed}
                </p>
              </div>
            </div>
          ))}
        </div>
      ))}
    </section >
  )
};

export default SectionPetsMaybe;
