import React from 'react';
import ListingImageGallery from './ListingImageGallery/ListingImageGallery';
import Overlay from '../ManageListingsPage/ManageListingCard/Overlay';
import { FormattedMessage } from '../../util/reactIntl';
import { getIsHouseOwnerListing } from '../../util/listingHelpers';
import { LISTING_STATE_CLOSED } from '../../util/types';

import css from './ListingPage.module.css';

const ShowClosedOverlayMaybe = ({ isClosed }) => {
  return isClosed ? (
    <Overlay
      message={
        <p className={css.filledLabel}>
          <FormattedMessage id="ManageListingCard.filledLabel" />
        </p>
      }
      className={css.overlayContent}
    >

    </Overlay>
  ) : null;
};

const SectionGallery = props => {
  const { listing, variantPrefix, intl } = props;
  const { id, images, attributes } = listing;
  const imageVariants = ['scaled-small', 'scaled-medium', 'scaled-large', 'scaled-xlarge'];
  // const thumbnailVariants = [variantPrefix, `${variantPrefix}-2x`, `${variantPrefix}-4x`];

  const isClosed = attributes?.state === LISTING_STATE_CLOSED;
  const isHouseSitBooking = getIsHouseOwnerListing(listing);

  return (
    <section className={css.productGallery} data-testid="carousel">
      <ListingImageGallery
        images={images}
        imageVariants={imageVariants}
        thumbnailVariants={['square-small2x']}
      />
      <ShowClosedOverlayMaybe
        isClosed={isClosed}
        currentListingId={id}
        intl={intl}
        isHouseSitBooking={isHouseSitBooking}
      />
    </section>
  );
};

export default SectionGallery;
